import React, { useState, useEffect } from 'react';
import supabase from '../services/supabaseClient';
import bankOptions from '../../data/bankOptions';
import Select from 'react-select';
import Modal from 'react-modal';
import { setAppElement } from 'react-modal';

setAppElement('#root');

const Onboarding = ({ leadId }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const bankOptionsFormatted = bankOptions.map(bank => ({ value: bank, label: bank }));

  const incomeOptions = [
    { value: 'Nomina', label: 'Nomina' },
    { value: 'Pension', label: 'Pension' },
    { value: 'Ayuda', label: 'Ayuda' },
    { value: 'Prestacion Desempleo', label: 'Prestacion Desempleo' },
    { value: 'Facturas', label: 'Facturas' },
    { value: 'No tiene ingresos', label: 'No tiene ingresos' },
  ];

  const relationOptions = [
    { value: 'conyuge', label: 'Cónyuge' },
    { value: 'pareja de hecho', label: 'Pareja de hecho' },
    { value: 'hijo/hija', label: 'Hijo/Hija' },
    { value: 'madre', label: 'Madre' },
    { value: 'padre', label: 'Padre' },
  ];

  const estadoCivilOptions = [
    { value: 'casado', label: 'Casado' },
    { value: 'divorciado', label: 'Divorciado' },
    { value: 'separado', label: 'Separado' },
  ];

  const siNoOptions = [
    { value: 'si', label: 'Sí' },
    { value: 'no', label: 'No' },
  ];

  const discapacidadOptions = [
    { value: 'no', label: 'No' },
    { value: 'superior33', label: 'Sí, superior al 33%' },
    { value: 'superior65', label: 'Sí, superior al 65%' },
    { value: 'intelectual33', label: 'Sí, discapacidad intelectual o enfermedad mental igual o superior al 33%' },
    { value: 'fisica65', label: 'Sí, discapacidad física o sensorial igual o superior al 65%' }
  ];

  const steps = [
    'Nr titulares y unidad familiar',
    'Revisa datos simulador',
    'Datos titulares y cónyuge',
    'Datos gestor'
  ];
  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      
      // 1. Fetch lead data
      const { data: leadData, error: leadError } = await supabase
        .from('leads')
        .select('*')
        .eq('id', leadId)
        .single();
  
      if (leadError) {
        console.error('Error fetching lead data:', leadError);
        setAlertMessage('Error al cargar los datos. Por favor, intente nuevamente.');
        setAlertModalIsOpen(true);
        return;
      }
  
      // 2. Fetch familiares data
      const { data: familiares, error: familiaresError } = await supabase
        .from('familiares')
        .select('*')
        .eq('cliente_id', leadId);
  
      if (familiaresError) {
        console.error('Error fetching familiares data:', familiaresError);
        setAlertMessage('Error al cargar los datos de familiares. Por favor, intente nuevamente.');
        setAlertModalIsOpen(true);
        return;
      }
  
      // Procesar los datos de familiares para el formData
      let processedFormData = {
        ...leadData,
        numeroTitulares: leadData.nr_titulares,
        miembrosUnidadFamiliar: leadData.miembros_familias,
        fechaVencimiento: leadData.fecha_vencimiento
      };
  
      // Procesar titulares y miembros familiares
      familiares?.forEach((familiar, index) => {
        if (familiar.relacion === 'titular1' || familiar.relacion === 'titular') {
          const titularIndex = familiar.relacion === 'titular1' ? 1 : index + 1;
          processedFormData = {
            ...processedFormData,
            [`titular${titularIndex}_nombre`]: familiar.nombre,
            [`titular${titularIndex}_apellidos`]: familiar.apellidos,
            [`titular${titularIndex}_dni`]: familiar.dni,
            [`titular${titularIndex}_tipoIngreso`]: familiar.tipoIngreso,
            [`titular${titularIndex}_ingresosBrutosAnuales`]: familiar.ingresosBrutosAnuales,
            [`titular${titularIndex}_ingresosNetosAnuales`]: familiar.ingresosNetosAnuales,
            [`titular${titularIndex}_gradoDiscapacidad`]: familiar.grado_discapacidad,
          };
  
          // Datos adicionales solo para el titular principal
          if (familiar.relacion === 'titular1') {
            processedFormData = {
              ...processedFormData,
              titular1_estadoCivil: familiar.estado_civil,
              titular1_familiaMonoparental: familiar.familia_monoparental,
              titular1_victimaViolencia: familiar.victima_violencia,
              titular1_gradoDiscapacidad: familiar.grado_discapacidad
            };
          }
        } else {
          // Para otros miembros de la familia
          const miembroIndex = index - (processedFormData.numeroTitulares - 1);
          processedFormData = {
            ...processedFormData,
            [`miembro${miembroIndex}_nombre`]: familiar.nombre,
            [`miembro${miembroIndex}_apellidos`]: familiar.apellidos,
            [`miembro${miembroIndex}_dni`]: familiar.dni,
            [`miembro${miembroIndex}_tipoIngreso`]: familiar.tipoIngreso,
            [`miembro${miembroIndex}_ingresosBrutosAnuales`]: familiar.ingresosBrutosAnuales,
            [`miembro${miembroIndex}_ingresosNetosAnuales`]: familiar.ingresosNetosAnuales,
            [`miembro${miembroIndex}_relacion`]: familiar.relacion,
            [`miembro${miembroIndex}_gradoDiscapacidad`]: familiar.grado_discapacidad
          };
        }
      });
  
      // 3. Fetch gestor data
      const { data: gestorData, error: gestorError } = await supabase
        .from('clientes')
        .select('*')
        .eq('id', leadId)
        .single();
  
      if (gestorData) {
        processedFormData = {
          ...processedFormData,
          gestor_nombre: gestorData.gestor_nombre,
          gestor_apellidos: gestorData.gestor_apellidos,
          gestor_email: gestorData.gestor_email,
          gestor_telefono: gestorData.gestor_telefono,
          gestor_direccion: gestorData.gestor_direccion
        };
      }
  
      setFormData(processedFormData);
  
    } catch (error) {
      console.error('Unexpected error fetching data:', error);
      setAlertMessage('Error inesperado al cargar los datos. Por favor, intente nuevamente.');
      setAlertModalIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (leadId) {
      fetchInitialData();
    }
  }, [leadId]);

  const updateSupabase = async (updatedData) => {
    try {
      const supabaseFormattedData = {
        nombre: updatedData.nombre,
        apellidos: updatedData.apellidos,
        telefono: updatedData.telefono,
        email: updatedData.email,
        bancoHipoteca: updatedData.bancoHipoteca,
        pagoMensualHipoteca: updatedData.pagoMensualHipoteca,
        cuotaJunio2022: updatedData.cuotaJunio2022,
        cuotaHace4Anos: updatedData.cuotaHace4Anos,
        ingresosBrutosAnuales: updatedData.ingresosBrutosAnuales,
        ingresosNetosAnuales: updatedData.ingresosNetosAnuales,
        ingresosHace4Anos: updatedData.ingresosHace4Anos,
        cantidadPorPagar: updatedData.cantidadPorPagar,
        plazoOriginalHipoteca: updatedData.plazoOriginalHipoteca,
        interesAnual: updatedData.interesAnual,
        domicilio: updatedData.domicilio,
        precioVivienda: updatedData.precioVivienda,
        firmasteHipoteca: updatedData.firmasteHipoteca,
        viviendaHabitual: updatedData.viviendaHabitual,
        gradoDiscapacidad: updatedData.gradoDiscapacidad,
        mayor60: updatedData.mayor60,
        dificultadesPago: updatedData.dificultadesPago,
        grupoFamiliar: updatedData.grupoFamiliar,
        provincia: updatedData.provincia,
        fecha_vencimiento: updatedData.fechaVencimiento,
        nr_titulares: updatedData.numeroTitulares,
        miembros_familias: updatedData.miembrosUnidadFamiliar,
        Carencia: updatedData.carencia,
        NuevaCuota: updatedData.nuevaCuota,
        InteresCarencia: updatedData.interesCarencia
      };
  
      const { error } = await supabase
        .from('leads')
        .update(supabaseFormattedData)
        .eq('id', leadId);
  
      if (error) {
        console.error('Error updating data:', error);
        setAlertMessage('Error al actualizar los datos. Por favor, intente nuevamente.');
        setAlertModalIsOpen(true);
      }
    } catch (error) {
      console.error('Unexpected error updating data:', error);
      setAlertMessage('Error inesperado al actualizar los datos. Por favor, intente nuevamente.');
      setAlertModalIsOpen(true);
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (Object.keys(formData).length > 0 && leadId) {
        updateSupabase(formData);
      }
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [formData, leadId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : ''
    }));
  };

  const validateStep = (currentStep) => {
    if (currentStep === 1) {
      return formData.numeroTitulares && formData.miembrosUnidadFamiliar;
    } else if (currentStep === 2) {
      return formData.nombre && formData.apellidos && formData.telefono && formData.email && 
             formData.bancoHipoteca && formData.domicilio;
    } else if (currentStep === 3) {
      const numeroTitulares = parseInt(formData.numeroTitulares, 10) || 0;
      const miembrosUnidadFamiliar = parseInt(formData.miembrosUnidadFamiliar, 10) || 0;

      // Validación especial para el primer titular
      if (!formData.titular1_nombre || !formData.titular1_apellidos || 
          !formData.titular1_dni || !formData.titular1_ingresosBrutosAnuales || 
          !formData.titular1_ingresosNetosAnuales || !formData.titular1_estadoCivil || 
          !formData.titular1_familiaMonoparental || !formData.titular1_victimaViolencia ||
          !formData.titular1_gradoDiscapacidad) {
        return false;
      }

      // Validación para titulares adicionales
      for (let i = 2; i <= numeroTitulares; i++) {
        if (!formData[`titular${i}_nombre`] || !formData[`titular${i}_apellidos`] || 
            !formData[`titular${i}_dni`] || !formData[`titular${i}_ingresosBrutosAnuales`] || 
            !formData[`titular${i}_ingresosNetosAnuales`]) {
          return false;
        }
      }

      // Validación para miembros de la unidad familiar
      for (let i = 1; i <= (miembrosUnidadFamiliar - numeroTitulares); i++) {
        if (!formData[`miembro${i}_nombre`] || !formData[`miembro${i}_apellidos`] || 
            !formData[`miembro${i}_dni`]) {
          return false;
        }
      }
      return true;
    } else if (currentStep === 4) {
      return formData.gestor_nombre && formData.gestor_apellidos && formData.gestor_email && 
             formData.gestor_telefono && formData.gestor_direccion;
    }
    return true;
  };
  const saveDataToSupabase = async () => {
    const numeroTitulares = parseInt(formData.numeroTitulares, 10) || 0;
    const miembrosUnidadFamiliar = parseInt(formData.miembrosUnidadFamiliar, 10) || 0;
  
    try {
      const clienteData = {
        nombre: formData.titular1_nombre,
        apellidos: formData.titular1_apellidos,
        telefono: formData.telefono,
        email: formData.email,
        bancoHipoteca: formData.bancoHipoteca,
        pagoMensualHipoteca: formData.pagoMensualHipoteca,
        cuotaJunio2022: formData.cuotaJunio2022,
        cuotaHace4Anos: formData.cuotaHace4Anos,
        ingresosBrutosAnuales: formData.ingresosBrutosAnuales,
        ingresosNetosAnuales: formData.ingresosNetosAnuales,
        ingresosHace4Anos: formData.ingresosHace4Anos,
        cantidadPorPagar: formData.cantidadPorPagar,
        plazoOriginalHipoteca: formData.plazoOriginalHipoteca,
        interesAnual: formData.interesAnual,
        numeroTitulares: formData.numeroTitulares,
        miembrosUnidadFamiliar: formData.miembrosUnidadFamiliar,
        fechaVencimiento: formData.fechaVencimiento,
        domicilio: formData.domicilio,
        Carencia: formData.carencia,
        NuevaCuota: formData.nuevaCuota,
        InteresCarencia: formData.interesCarencia,
        numero_prestamo_hipotecario: formData.numero_prestamo_hipotecario,
        precio_vivienda: formData.precioVivienda,
        vivienda_habitual: formData.viviendaHabitual,
        fecha_firma_hipoteca: formData.firmasteHipoteca,
        dificultades_pago: formData.dificultadesPago,
        anos_faltantes: formData.anosFaltantes,
        provincia: formData.provincia,
        estado_civil: formData.titular1_estadoCivil,
        familia_monoparental: formData.titular1_familiaMonoparental,
        victima_violencia: formData.titular1_victimaViolencia,
        gestor_nombre: formData.gestor_nombre || null,
        gestor_apellidos: formData.gestor_apellidos || null,
        gestor_email: formData.gestor_email || null,
        gestor_telefono: formData.gestor_telefono || null,
        gestor_direccion: formData.gestor_direccion || null
      };
  
      const { error: clienteError } = await supabase
        .from('clientes')
        .update(clienteData)
        .eq('id', leadId);
  
      if (clienteError) {
        console.error('Error al actualizar cliente en Supabase:', clienteError);
        throw clienteError;
      }
  
      console.log('Datos del cliente actualizados correctamente');
  
      // Eliminar familiares existentes
      const { error: deleteError } = await supabase
        .from('familiares')
        .delete()
        .eq('cliente_id', leadId);
  
      if (deleteError) {
        console.error('Error al eliminar familiares existentes:', deleteError);
        throw deleteError;
      }
  
      // Insertar titulares
      for (let i = 1; i <= numeroTitulares; i++) {
        const { error: titularError } = await supabase
          .from('familiares')
          .insert([{
            cliente_id: leadId,
            nombre: formData[`titular${i}_nombre`],
            apellidos: formData[`titular${i}_apellidos`],
            dni: formData[`titular${i}_dni`],
            tipoIngreso: formData[`titular${i}_tipoIngreso`],
            ingresosBrutosAnuales: formData[`titular${i}_ingresosBrutosAnuales`],
            ingresosNetosAnuales: formData[`titular${i}_ingresosNetosAnuales`],
            relacion: i === 1 ? 'titular1' : 'titular',
            domicilio: formData.domicilio,
            estado_civil: i === 1 ? formData.titular1_estadoCivil : null,
            familia_monoparental: i === 1 ? formData.titular1_familiaMonoparental : null,
            victima_violencia: i === 1 ? formData.titular1_victimaViolencia : null,
            grado_discapacidad: formData[`titular${i}_gradoDiscapacidad`],
          }]);
  
        if (titularError) {
          console.error(`Error al insertar titular ${i}:`, titularError);
          throw titularError;
        }
      }
  
      // Insertar miembros de la unidad familiar
      for (let i = 1; i <= (miembrosUnidadFamiliar - numeroTitulares); i++) {
        const { error: miembroError } = await supabase
          .from('familiares')
          .insert([{
            cliente_id: leadId,
            nombre: formData[`miembro${i}_nombre`],
            apellidos: formData[`miembro${i}_apellidos`],
            dni: formData[`miembro${i}_dni`],
            tipoIngreso: formData[`miembro${i}_tipoIngreso`],
            ingresosBrutosAnuales: formData[`miembro${i}_ingresosBrutosAnuales`],
            ingresosNetosAnuales: formData[`miembro${i}_ingresosNetosAnuales`],
            relacion: formData[`miembro${i}_relacion`],
            domicilio: formData.domicilio,
            grado_discapacidad: formData[`miembro${i}_gradoDiscapacidad`],
          }]);
  
        if (miembroError) {
          console.error(`Error al insertar miembro familiar ${i}:`, miembroError);
          throw miembroError;
        }
      }
  
      console.log('Todo el proceso de actualización completado correctamente');
      setSuccessModalIsOpen(true);
      setTimeout(() => {
        setSuccessModalIsOpen(false);
      }, 3000);
  
    } catch (error) {
      console.error('Error completo al guardar datos en Supabase:', error);
      setAlertMessage('Error al guardar los datos. Por favor, inténtelo de nuevo.');
      setAlertModalIsOpen(true);
    }
  };

  const handleFinalizarPrimerosPasos = async () => {
    setIsSubmitting(true);
    await saveDataToSupabase();
    setIsSubmitting(false);
  };

  const nextStep = async () => {
    if (validateStep(step)) {
      setStep((prevStep) => prevStep + 1);
    } else {
      setAlertMessage('Por favor, complete todos los campos requeridos para avanzar.');
      setAlertModalIsOpen(true);
    }
  };

  const closeAlertModal = () => {
    setAlertModalIsOpen(false);
  };

  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };
  const renderTitulares = () => {
    const titulares = [];
    const numeroTitulares = parseInt(formData.numeroTitulares, 10) || 0;
    const miembrosUnidadFamiliar = parseInt(formData.miembrosUnidadFamiliar, 10) || 0;

    // Para el primer titular (tiene campos adicionales)
    titulares.push(
      <div key={`titular-1`} className="border-b-2 border-gray-300 py-4">
        <h3 className="text-lg font-semibold mb-2">Titular 1</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {[
            { label: 'Nombre', name: `titular1_nombre` },
            { label: 'Apellidos', name: `titular1_apellidos` },
            { label: 'DNI', name: `titular1_dni` },
            { label: 'Ingresos brutos anuales actuales', name: `titular1_ingresosBrutosAnuales` },
            { label: 'Ingresos netos anuales actuales', name: `titular1_ingresosNetosAnuales` },
          ].map((field, index) => (
            <div key={index}>
              <label className="block text-gray-700 mb-2">{field.label}</label>
              <input
                type="text"
                name={field.name}
                value={formData[field.name] || ''}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded bg-gray-100"
              />
            </div>
          ))}

          <div>
            <label className="block text-gray-700 mb-2">Estado Civil</label>
            <Select
              name="titular1_estadoCivil"
              value={estadoCivilOptions.find(option => option.value === formData.titular1_estadoCivil)}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'titular1_estadoCivil')}
              options={estadoCivilOptions}
              className="w-full"
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-2">Familia Monoparental</label>
            <Select
              name="titular1_familiaMonoparental"
              value={siNoOptions.find(option => option.value === formData.titular1_familiaMonoparental)}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'titular1_familiaMonoparental')}
              options={siNoOptions}
              className="w-full"
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-2">Víctima de violencia de género</label>
            <Select
              name="titular1_victimaViolencia"
              value={siNoOptions.find(option => option.value === formData.titular1_victimaViolencia)}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'titular1_victimaViolencia')}
              options={siNoOptions}
              className="w-full"
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-2">Tipo ingreso</label>
            <Select
              name={`titular1_tipoIngreso`}
              value={incomeOptions.find(option => option.value === formData[`titular1_tipoIngreso`])}
              onChange={(selectedOption) => handleSelectChange(selectedOption, `titular1_tipoIngreso`)}
              options={incomeOptions}
              className="w-full"
            />
          </div>

          <div className="col-span-2">
            <label className="block text-gray-700 mb-2">
              ¿Existe algún grado de discapacidad?
            </label>
            <Select
              name="titular1_gradoDiscapacidad"
              value={discapacidadOptions.find(option => option.value === formData.titular1_gradoDiscapacidad)}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'titular1_gradoDiscapacidad')}
              options={discapacidadOptions}
              className="w-full"
            />
          </div>
        </div>
      </div>
    );

    // Para los titulares adicionales (2 en adelante)
    for (let i = 2; i <= numeroTitulares; i++) {
      titulares.push(
        <div key={`titular-${i}`} className="border-b-2 border-gray-300 py-4">
          <h3 className="text-lg font-semibold mb-2">Titular {i}</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {[
              { label: 'Nombre', name: `titular${i}_nombre` },
              { label: 'Apellidos', name: `titular${i}_apellidos` },
              { label: 'DNI', name: `titular${i}_dni` },
              { label: 'Ingresos brutos anuales actuales', name: `titular${i}_ingresosBrutosAnuales` },
              { label: 'Ingresos netos anuales actuales', name: `titular${i}_ingresosNetosAnuales` },
            ].map((field, index) => (
              <div key={index}>
                <label className="block text-gray-700 mb-2">{field.label}</label>
                <input
                  type="text"
                  name={field.name}
                  value={formData[field.name] || ''}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded bg-gray-100"
                />
              </div>
            ))}
            <div>
              <label className="block text-gray-700 mb-2">Tipo ingreso</label>
              <Select
                name={`titular${i}_tipoIngreso`}
                value={incomeOptions.find(option => option.value === formData[`titular${i}_tipoIngreso`])}
                onChange={(selectedOption) => handleSelectChange(selectedOption, `titular${i}_tipoIngreso`)}
                options={incomeOptions}
                className="w-full"
              />
            </div>
            <div className="col-span-2">
              <label className="block text-gray-700 mb-2">
                ¿Existe algún grado de discapacidad?
              </label>
              <Select
                name={`titular${i}_gradoDiscapacidad`}
                value={discapacidadOptions.find(option => option.value === formData[`titular${i}_gradoDiscapacidad`])}
                onChange={(selectedOption) => handleSelectChange(selectedOption, `titular${i}_gradoDiscapacidad`)}
                options={discapacidadOptions}
                className="w-full"
              />
            </div>
          </div>
        </div>
      );
    }

    // Para los miembros de la unidad familiar
    for (let i = 1; i <= (miembrosUnidadFamiliar - numeroTitulares); i++) {
      titulares.push(
        <div key={`miembro-${i}`} className="border-b-2 border-gray-300 py-4">
          <h3 className="text-lg font-semibold mb-2">Miembro unidad familiar {i}</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {[
              { label: 'Nombre', name: `miembro${i}_nombre` },
              { label: 'Apellidos', name: `miembro${i}_apellidos` },
              { label: 'DNI', name: `miembro${i}_dni` },
              { label: 'Ingresos brutos anuales actuales', name: `miembro${i}_ingresosBrutosAnuales` },
              { label: 'Ingresos netos anuales actuales', name: `miembro${i}_ingresosNetosAnuales` },
            ].map((field, index) => (
              <div key={index}>
                <label className="block text-gray-700 mb-2">{field.label}</label>
                <input
                  type="text"
                  name={field.name}
                  value={formData[field.name] || ''}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded bg-gray-100"
                />
              </div>
            ))}
            <div>
              <label className="block text-gray-700 mb-2">Tipo ingreso</label>
              <Select
                name={`miembro${i}_tipoIngreso`}
                value={incomeOptions.find(option => option.value === formData[`miembro${i}_tipoIngreso`])}
                onChange={(selectedOption) => handleSelectChange(selectedOption, `miembro${i}_tipoIngreso`)}
                options={incomeOptions}
                className="w-full"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-2">Indica que es para ti</label>
              <Select
                name={`miembro${i}_relacion`}
                value={relationOptions.find(option => option.value === formData[`miembro${i}_relacion`])}
                onChange={(selectedOption) => handleSelectChange(selectedOption, `miembro${i}_relacion`)}
                options={relationOptions}
                className="w-full"
              />
            </div>
            <div className="col-span-2">
              <label className="block text-gray-700 mb-2">
                ¿Existe algún grado de discapacidad?
              </label>
              <Select
                name={`miembro${i}_gradoDiscapacidad`}
                value={discapacidadOptions.find(option => option.value === formData[`miembro${i}_gradoDiscapacidad`])}
                onChange={(selectedOption) => handleSelectChange(selectedOption, `miembro${i}_gradoDiscapacidad`)}
                options={discapacidadOptions}
                className="w-full"
              />
            </div>
          </div>
        </div>
      );
    }

    return titulares;
  };
  return (
    <div className="flex flex-col items-center mt-12 px-4 sm:px-0">
      {isLoading ? (
        <div className="w-full max-w-6xl bg-white p-6 shadow-xl rounded-lg flex items-center justify-center">
          <div className="text-xl">Cargando datos...</div>
        </div>
      ) : (
        <div className="w-full max-w-6xl bg-white p-6 shadow-xl rounded-lg">
          <div className="mb-8">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0">
              {steps.map((title, index) => (
                <div
                  key={index}
                  className={`flex-1 text-center py-2 px-4 border-b-2 cursor-pointer ${
                    step === index + 1 ? 'font-bold text-purple-600 border-purple-600' : 
                    'text-gray-400 border-transparent hover:text-purple-600 hover:border-purple-600'
                  }`}
                  onClick={() => setStep(index + 1)}
                >
                  {title}
                </div>
              ))}
            </div>
          </div>

          {/* Step 1 */}
          {step === 1 && (
            <>
              <h2 className="text-xl font-semibold mb-4">
                ¿Cuantos titulares hay en la hipoteca incluyendote a ti?
                <br />(Ojo, solo titulares, no avalistas o fiadores)
              </h2>
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label className="block text-gray-700 mb-2">Número de titulares</label>
                  <input
                    type="number"
                    name="numeroTitulares"
                    value={formData.numeroTitulares || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded bg-gray-100"
                  />
                </div>
                <div>
                  <h2 className="text-xl font-semibold mb-4">
                    ¿Cuantas personas incluyendote a ti estan empadronados en la vivienda?
                    <br />(Empadronados y que tengan ingresos)
                  </h2>
                  <label className="block text-gray-700 mb-2">Miembros de la unidad familiar</label>
                  <input
                    type="number"
                    name="miembrosUnidadFamiliar"
                    value={formData.miembrosUnidadFamiliar || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded bg-gray-100"
                  />
                </div>
              </div>
            </>
          )}

          {/* Step 2 */}
          {step === 2 && (
            <>
              <h2 className="text-xl font-semibold mb-4">
                Revisa los datos que has rellenado en el simulador. Comprueba que no hay ningún error y modifica lo que consideres oportuno
              </h2>
              <form className="grid grid-cols-1 gap-6">
                {/* Datos personales */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="text-lg font-medium mb-3">Datos personales</h3>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {[
                      { label: 'Nombre', name: 'nombre' },
                      { label: 'Apellidos', name: 'apellidos' },
                      { label: 'Teléfono', name: 'telefono' },
                      { label: 'Email', name: 'email' },
                      { label: 'Provincia', name: 'provincia' },
                      { label: 'Domicilio completo', name: 'domicilio' },
                    ].map((field, index) => (
                      <div key={index}>
                        <label className="block text-gray-700 mb-2">{field.label}</label>
                        <input
                          type="text"
                          name={field.name}
                          value={formData[field.name] || ''}
                          onChange={handleChange}
                          className="w-full px-3 py-2 border rounded bg-gray-100"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Datos de la vivienda */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="text-lg font-medium mb-3">Datos de la vivienda</h3>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {[
                      { label: 'Precio de la vivienda', name: 'precioVivienda' },
                      { 
                        label: '¿Es vivienda habitual?', 
                        name: 'viviendaHabitual', 
                        type: 'select', 
                        options: [
                          { value: 'si', label: 'Sí' },
                          { value: 'no', label: 'No' }
                        ]
                      },
                      { label: '¿Firmaste la hipoteca antes de octubre del 2022?', name: 'firmasteHipoteca' },
                    ].map((field, index) => (
                      <div key={index}>
                        <label className="block text-gray-700 mb-2">{field.label}</label>
                        {field.type === 'select' ? (
                          <Select
                            name={field.name}
                            value={field.options.find(option => option.value === formData[field.name])}
                            onChange={(selectedOption) => handleSelectChange(selectedOption, field.name)}
                            options={field.options}
                            className="w-full"
                          />
                        ) : (
                          <input
                            type="text"
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded bg-gray-100"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Datos de la hipoteca */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="text-lg font-medium mb-3">Datos de la hipoteca</h3>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {[
                      { label: 'Banco de la hipoteca', name: 'bancoHipoteca', type: 'bankSelect' },
                      { label: 'Cuota mensual actual', name: 'pagoMensualHipoteca' },
                      { label: 'Cuota Junio 2022', name: 'cuotaJunio2022' },
                      { label: 'Cuota hace 4 años', name: 'cuotaHace4Anos' },
                      { label: 'Cantidad pendiente por pagar', name: 'cantidadPorPagar' },
                      { label: 'Fecha de vencimiento', name: 'fechaVencimiento', type: 'date' },
                      { label: 'Plazo original (años)', name: 'plazoOriginalHipoteca' },
                      { label: 'Interés anual (%)', name: 'interesAnual' },
                      { label: 'Años faltantes', name: 'anosFaltantes' },
                    ].map((field, index) => (
                      <div key={index}>
                        <label className="block text-gray-700 mb-2">{field.label}</label>
                        {field.type === 'bankSelect' ? (
                          <Select
                            name={field.name}
                            value={bankOptionsFormatted.find(option => option.value === formData[field.name])}
                            onChange={(selectedOption) => handleSelectChange(selectedOption, field.name)}
                            options={bankOptionsFormatted}
                            className="w-full"
                          />
                        ) : field.type === 'date' ? (
                          <input
                            type="date"
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded bg-gray-100"
                          />
                        ) : (
                          <input
                            type="text"
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded bg-gray-100"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Datos económicos y situación personal */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="text-lg font-medium mb-3">Datos económicos y situación personal</h3>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {[
                      { label: 'Ingresos brutos anuales', name: 'ingresosBrutosAnuales' },
                      { label: 'Ingresos netos anuales', name: 'ingresosNetosAnuales' },
                      { label: 'Ingresos hace 4 años', name: 'ingresosHace4Anos' },
                      { 
                        label: '¿Mayor de 60 años?', 
                        name: 'mayor60', 
                        type: 'select', 
                        options: [
                          { value: 'si', label: 'Sí' },
                          { value: 'no', label: 'No' }
                        ]
                      },
                      { 
                        label: '¿Dificultades de pago?', 
                        name: 'dificultadesPago', 
                        type: 'select', 
                        options: [
                          { value: 'si', label: 'Sí' },
                          { value: 'no', label: 'No' }
                        ]
                      },
                    ].map((field, index) => (
                      <div key={index}>
                        <label className="block text-gray-700 mb-2">{field.label}</label>
                        {field.type === 'select' ? (
                          <Select
                            name={field.name}
                            value={field.options.find(option => option.value === formData[field.name])}
                            onChange={(selectedOption) => handleSelectChange(selectedOption, field.name)}
                            options={field.options}
                            className="w-full"
                          />
                        ) : (
                          <input
                            type="text"
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border rounded bg-gray-100"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </form>
            </>
          )}
          {/* Step 3 */}
          {step === 3 && (
            <>
              <h2 className="text-xl font-semibold mb-4">Datos titulares y cónyuge</h2>
              {(!formData.numeroTitulares || !formData.miembrosUnidadFamiliar) ? (
                <p className="text-red-600">Por favor, complete los datos del primer paso antes de continuar.</p>
              ) : (
                <form className="grid grid-cols-1 gap-6">
                  {renderTitulares()}
                </form>
              )}
            </>
          )}

          {/* Step 4 */}
          {step === 4 && (
            <>
              <h2 className="text-xl font-semibold mb-4">Datos del gestor</h2>
              <form className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {[
                  { label: 'Nombre', name: 'gestor_nombre' },
                  { label: 'Apellidos', name: 'gestor_apellidos' },
                  { label: 'Correo', name: 'gestor_email' },
                  { label: 'Teléfono', name: 'gestor_telefono' },
                  { label: 'Dirección', name: 'gestor_direccion' },
                ].map((field, index) => (
                  <div key={index}>
                    <label className="block text-gray-700 mb-2">{field.label}</label>
                    <input
                      type="text"
                      name={field.name}
                      value={formData[field.name] || ''}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded bg-gray-100"
                    />
                  </div>
                ))}
              </form>
              <button
                onClick={handleFinalizarPrimerosPasos}
                disabled={isSubmitting}
                className={`w-full py-2 rounded transition duration-200 mt-4 ${
                  isSubmitting
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-purple-600 text-white hover:bg-purple-700'
                }`}
              >
                {isSubmitting ? 'Guardando...' : 'Finalizar Primeros pasos'}
              </button>
            </>
          )}

          {/* Botón Siguiente (excepto en el último paso) */}
          {step !== 4 && (
            <button
              onClick={nextStep}
              className="w-full py-2 bg-purple-600 text-white rounded hover:bg-purple-700 transition duration-200 mt-4"
            >
              Siguiente
            </button>
          )}
        </div>
      )}

      {/* Modal de Alerta */}
      <Modal
        isOpen={alertModalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Modal Alerta"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-[80vh] overflow-auto">
          <h2 className="text-2xl font-semibold mb-4">Información Incompleta</h2>
          <p>{alertMessage}</p>
          <button
            onClick={closeAlertModal}
            className="mt-4 bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 transition duration-200"
          >
            Cerrar
          </button>
        </div>
      </Modal>

      {/* Modal de Éxito */}
      <Modal
        isOpen={successModalIsOpen}
        onRequestClose={closeSuccessModal}
        contentLabel="Modal Éxito"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-[80vh] overflow-auto text-center">
          <h2 className="text-2xl font-semibold mb-4">¡Felicidades!</h2>
          <p>Su solicitud ha sido enviada con éxito.</p>
        </div>
      </Modal>
    </div>
  );
};

export default Onboarding;