import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import Notas from './Notas';
import Correos from './Correos';
import Llamadas from './Llamadas';
import Tareas from './Tareas';
import HonorariosPagosCuotas from './HonorariosPagosCuotas';
import SimulacionCBP from './simulador/SimulacionCBP';
import PrepararExpediente from './prepararexpediente/PrepararExpediente'

const Actividades = ({ leadId, clienteId, hideExpediente = false }) => {
  const [actividades, setActividades] = useState([]);
  const [activeTab, setActiveTab] = useState('Notas');

  const fetchActividades = async () => {
    const { data: actividadesData } = await supabase
      .from('actividades')
      .select('*')
      .or(`lead_id.eq.${leadId},cliente_id.eq.${clienteId}`);

    setActividades(actividadesData || []);
  };

  useEffect(() => {
    fetchActividades();
  }, [leadId, clienteId]);

  return (
    <div className="mt-0">
      <div className="flex border-b mb-2">
        {[
          'Simulador',
          ...(!hideExpediente ? ['Preparar Expediente'] : []),
          'Notas',
          'Tareas',
          'Correos',
          'Llamadas',
          'Honorarios y pagos'
        ].map((tab) => (
          <button
            key={tab}
            className={`px-4 py-2 -mb-px font-semibold border-b-2 ${
              activeTab === tab ? 'text-purple-600 border-purple-600' : 'text-gray-600 border-transparent'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div>
        {activeTab === 'Simulador' && <SimulacionCBP leadId={leadId} clienteId={clienteId} refreshActividades={fetchActividades} />}
        {activeTab === 'Preparar Expediente' && <PrepararExpediente leadId={leadId} clienteId={clienteId} refreshActividades={fetchActividades} />}
        {activeTab === 'Notas' && <Notas leadId={leadId} clienteId={clienteId} refreshActividades={fetchActividades} />}
        {activeTab === 'Tareas' && <Tareas leadId={leadId} clienteId={clienteId} />}
        {activeTab === 'Correos' && <Correos leadId={leadId} clienteId={clienteId} />}
        {activeTab === 'Llamadas' && <Llamadas leadId={leadId} clienteId={clienteId} />}
        {activeTab === 'Honorarios y pagos' && <HonorariosPagosCuotas userId={clienteId} />}
      </div>
    </div>
  );
};

export default Actividades;