import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Table from '../../components/Table';
import { supabase } from '../../utils/supabaseClient';

const Leads = () => {
  const [data, setData] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [editableLeadId, setEditableLeadId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLead, setNewLead] = useState({
    nombre: '',
    apellidos: '',
    telefono: '',
    email: '',
    provincia: ''
  });

  useEffect(() => {
    const fetchLeads = async () => {
      // Fetch data from leads
      const { data: resultados, error: errorResultados } = await supabase
        .from('leads')
        .select('id, nombre, apellidos, telefono, email, provincia, usuario_asignado');

      if (errorResultados) {
        console.error('Error fetching leads:', errorResultados);
        return;
      }

      // Fetch data from customer_journey using the IDs from leads
      const ids = resultados.map(resultado => resultado.id);
      const { data: journey, error: errorJourney } = await supabase
        .from('customer_journey')
        .select('user_id, estado')
        .in('user_id', ids);

      if (errorJourney) {
        console.error('Error fetching customer_journey:', errorJourney);
        return;
      }

      // Combine data
      const combinedData = resultados.map(resultado => {
        const journeyData = journey.find(j => j.user_id === resultado.id);
        return {
          ...resultado,
          estado: journeyData ? journeyData.estado : 'N/A',
        };
      });

      setData(combinedData);
    };

    const fetchUsuarios = async () => {
      const { data: usuarios, error } = await supabase
        .from('usuarios')
        .select('user_id, nombre, apellidos');

      if (error) {
        console.error('Error fetching usuarios:', error);
        return;
      }

      setUsuarios(usuarios.map(usuario => ({
        value: usuario.user_id,
        label: `${usuario.nombre} ${usuario.apellidos}`,
      })));
    };

    fetchLeads();
    fetchUsuarios();
  }, []);

  const handleUsuarioChange = async (leadId, userId) => {
    const { error } = await supabase
      .from('leads')
      .update({ usuario_asignado: userId })
      .eq('id', leadId);

    if (error) {
      console.error('Error updating usuario_asignado:', error);
    } else {
      setEditableLeadId(null);
      setData((prevData) =>
        prevData.map((lead) =>
          lead.id === leadId ? { ...lead, usuario_asignado: userId } : lead
        )
      );
    }
  };

  const handleCreateLead = async () => {
    // Validación básica
    if (!newLead.nombre || !newLead.apellidos || !newLead.telefono || !newLead.email) {
      alert('Por favor, completa los campos obligatorios (nombre, apellidos, teléfono y email)');
      return;
    }
  
    try {
      // Primero creamos el usuario en auth.users
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email: newLead.email,
        password: generateRandomPassword(),
        options: {
          data: {
            nombre: newLead.nombre,
            apellidos: newLead.apellidos
          }
        }
      });
  
      if (authError) {
        console.error('Error creating auth user:', authError);
        alert('Error al crear el usuario');
        return;
      }
  
      // Insertamos el nuevo lead usando el ID del usuario creado
      const { data: insertedLead, error } = await supabase
        .from('leads')
        .insert([{
          id: authData.user.id,
          nombre: newLead.nombre,
          apellidos: newLead.apellidos,
          telefono: newLead.telefono,
          email: newLead.email,
          provincia: newLead.provincia,
          created_at: new Date().toISOString()
        }])
        .select();
  
      if (error) {
        console.error('Error creating lead:', error);
        alert('Error al crear el lead');
        return;
      }
  
      // Crear entrada en user_progress
      const { error: progressError } = await supabase
        .from('user_progress')
        .insert([{
          user_id: authData.user.id,
          nombre: newLead.nombre,
          apellidos: newLead.apellidos,
          telefono: newLead.telefono,
          estado: 'Nuevo',
          politicaPrivacidad: true, // o false según tu lógica
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          formData: {}, // O cualquier dato inicial que necesites
          current_question: 0 // O el valor inicial que prefieras
        }]);
  
      if (progressError) {
        console.error('Error creating user progress:', progressError);
        return;
      }
  
      // Ahora sí, crear entrada en customer_journey
      if (insertedLead && insertedLead[0]) {
        const currentTimestamp = new Date().toISOString();
        const { error: journeyError } = await supabase
          .from('customer_journey')
          .insert([{
            user_id: authData.user.id,
            estado: 'Nuevo',
            timestamp: currentTimestamp,
            updated_at: currentTimestamp,
            prevision_primerpago: null
          }]);
  
        if (journeyError) {
          console.error('Error creating customer journey:', journeyError);
        }
      }
  
      // Actualizar la lista de leads
      setData([...data, { ...insertedLead[0], estado: 'Nuevo' }]);
      
      // Cerrar el modal y resetear el formulario
      setIsModalOpen(false);
      setNewLead({
        nombre: '',
        apellidos: '',
        telefono: '',
        email: '',
        provincia: ''
      });
  
      alert('Lead creado exitosamente');
    } catch (error) {
      console.error('Error:', error);
      alert('Error al crear el lead');
    }
  };
  
  // Función para generar una contraseña aleatoria
  const generateRandomPassword = () => {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
  };

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'nombre',
      Cell: ({ row }) => (
        <Link to={`/leads/${row.original.id}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          {row.original.nombre}
        </Link>
      ),
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Correo',
      accessor: 'email',
    },
    {
      Header: 'Teléfono',
      accessor: 'telefono',
    },
    {
      Header: 'Provincia',
      accessor: 'provincia',
    },
    {
      Header: 'Estado',
      accessor: 'estado',
    },
    {
      Header: 'Usuario Asignado',
      accessor: 'usuario_asignado',
      Cell: ({ row }) => {
        const usuarioAsignado = row.original.usuario_asignado;
        const isEditable = editableLeadId === row.original.id;
        return (
          <div className="flex items-center justify-between">
            {isEditable ? (
              <Select
                options={usuarios}
                onChange={(selectedOption) => handleUsuarioChange(row.original.id, selectedOption.value)}
                placeholder="Asignar Usuario"
                className="w-56"
                styles={customStyles}
                menuPortalTarget={document.body}
              />
            ) : (
              <span>{usuarios.find(user => user.value === usuarioAsignado)?.label || 'No asignado'}</span>
            )}
            <button
              className="ml-2 text-gray-500 hover:text-gray-700"
              onClick={() => setEditableLeadId(isEditable ? null : row.original.id)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold">Leads</h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center text-white px-4 py-2 rounded-lg bg-gradient-to-r from-purple-500 to-blue-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
          Nuevo Lead
        </button>
      </div>

      <Table columns={columns} data={data} />

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg w-full max-w-xl">
            <h2 className="text-2xl font-bold mb-6">Nuevo Lead</h2>
            
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Nombre</label>
                <input
                  type="text"
                  value={newLead.nombre}
                  onChange={(e) => setNewLead({...newLead, nombre: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Apellidos</label>
                <input
                  type="text"
                  value={newLead.apellidos}
                  onChange={(e) => setNewLead({...newLead, apellidos: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Teléfono</label>
                <input
                  type="tel"
                  value={newLead.telefono}
                  onChange={(e) => setNewLead({...newLead, telefono: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                <input
                  type="email"
                  value={newLead.email}
                  onChange={(e) => setNewLead({...newLead, email: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Provincia</label>
                <input
                  type="text"
                  value={newLead.provincia}
                  onChange={(e) => setNewLead({...newLead, provincia: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 h-12 px-4 text-base"
                />
              </div>
            </div>

            <div className="mt-8 flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="flex items-center text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200"
              >
                Cancelar
              </button>
              <button
                onClick={handleCreateLead}
                className="flex items-center text-white px-4 py-2 rounded-lg bg-gradient-to-r from-purple-500 to-blue-500"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leads;