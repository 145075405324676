import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import { solicitudReestructuracion } from '../../config/documentConfig';
import supabase from '../services/supabaseClient';

const GenerarDocumentacion = ({ leadId }) => {
  // Estados
  const [cliente, setCliente] = useState(null);
  const [dniTitular1, setDniTitular1] = useState(null);
  const [porcentajeCuotaIngresosNetos, setPorcentajeCuotaIngresosNetos] = useState(null);
  const [documentosSolicitados, setDocumentosSolicitados] = useState([]);
  const [error, setError] = useState(null);
  const [textoUsuario, setTextoUsuario] = useState('');
  const [familiares, setFamiliares] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Efecto para cargar datos iniciales
  useEffect(() => {
    const cargarDatos = async () => {
      // Validar que leadId sea válido
      if (!leadId) {
        setError('ID no válido');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        // Fetch client data
        const { data: clienteData, error: clienteError } = await supabase
          .from('clientes')
          .select(`
            nombre, 
            apellidos, 
            bancoHipoteca, 
            numero_prestamo_hipotecario,
            ingresosBrutosAnuales,
            cuotaHace4Anos,
            pagoMensualHipoteca,
            ingresosNetosAnuales,
            Carencia,
            NuevaCuota,
            InteresCarencia
          `)
          .eq('id', leadId)
          .single();

        if (clienteError) {
          if (clienteError.code === 'PGRST116') {
            throw new Error('No se encontró el cliente especificado');
          }
          throw clienteError;
        }

        if (!clienteData) {
          throw new Error('No se encontraron datos del cliente');
        }

        setCliente(clienteData);

        // Calcular porcentaje solo si los valores necesarios existen
        if (clienteData.pagoMensualHipoteca && clienteData.ingresosNetosAnuales) {
          const porcentaje = ((clienteData.pagoMensualHipoteca * 12) / clienteData.ingresosNetosAnuales) * 100;
          setPorcentajeCuotaIngresosNetos(porcentaje.toFixed(2));
        }

        // Fetch familiares data
        const { data: familiaresData, error: familiaresError } = await supabase
          .from('familiares')
          .select(`
            nombre,
            apellidos,
            dni,
            tipoIngreso,
            ingresosBrutosAnuales,
            ingresosNetosAnuales,
            relacion
          `)
          .eq('cliente_id', leadId);

        if (familiaresError) throw familiaresError;
        
        setFamiliares(familiaresData || []);

        // Buscar específicamente el DNI del titular1
        const titular1 = familiaresData?.find(f => f.relacion === 'titular1');
        if (titular1) {
          setDniTitular1(titular1.dni);
        }

        // Fetch documentos solicitados
        const { data: documentosData, error: documentosError } = await supabase
          .from('documentacion_solicitada')
          .select('*')
          .eq('cliente_id', leadId)
          .order('created_at', { ascending: false });

        if (documentosError) throw documentosError;
        setDocumentosSolicitados(documentosData);

      } catch (error) {
        console.error('Error al cargar datos:', error);
        setError(error.message || 'Hubo un error al cargar los datos. Por favor, actualice la página.');
      } finally {
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, [leadId]);

  // Función para calcular ingresos totales de la unidad familiar
  const calcularIngresosTotales = () => {
    return familiares.reduce((total, familiar) => {
      return total + (parseFloat(familiar.ingresosBrutosAnuales) || 0);
    }, 0);
  };

  // Función para generar texto de situación familiar
  const generarTextoSituacionFamiliar = () => {
    let texto = 'La unidad familiar está compuesta por:\n';
    familiares.forEach(familiar => {
      texto += `- ${familiar.nombre} ${familiar.apellidos} (${familiar.relacion}), `;
      texto += `con ingresos brutos anuales de ${familiar.ingresosBrutosAnuales}€ `;
      texto += `(tipo de ingreso: ${familiar.tipoIngreso})\n`;
    });
    return texto;
  };

  // Función para generar PDF
  const generarPDF = () => {
    try {
      const doc = new jsPDF();
      let yOffset = 20;
      const today = new Date();
      const dateStr = `A ${today.getDate().toString().padStart(2, '0')} de ${today.toLocaleString('es-ES', { month: 'long' })} de ${today.getFullYear()}`;

      // Reemplazar placeholders en el contenido
      const content = solicitudReestructuracion.content.map(section => {
        const replacePlaceholders = (text) => {
          return text
            .replace('{{nombre}}', cliente?.nombre || '')
            .replace('{{apellidos}}', cliente?.apellidos || '')
            .replace('{{dniTitular1}}', dniTitular1 || '')
            .replace('{{numero_prestamo_hipotecario}}', cliente?.numero_prestamo_hipotecario || '')
            .replace('{{bancoHipoteca}}', cliente?.bancoHipoteca || '')
            .replace('{{ingresosBrutosAnuales}}', calcularIngresosTotales() || '')
            .replace('{{cuotaHace4Anos}}', cliente?.cuotaHace4Anos || '')
            .replace('{{pagoMensualHipoteca}}', cliente?.pagoMensualHipoteca || '')
            .replace('{{fecha}}', dateStr)
            .replace('{{porcentajeCuotaIngresosNetos}}', porcentajeCuotaIngresosNetos || '')
            .replace('{{textoUsuario}}', `${textoUsuario}\n\n${generarTextoSituacionFamiliar()}` || '');
        };

        if (section.type === 'paragraph') {
          return { ...section, text: replacePlaceholders(section.text) };
        }
        if (section.type === 'list') {
          return { ...section, items: section.items.map(replacePlaceholders) };
        }
        return section;
      });

      // Renderizar contenido en el PDF
      content.forEach((section) => {
        // Función auxiliar para manejar nueva página
        const checkNewPage = () => {
          if (yOffset > 270) {
            doc.addPage();
            yOffset = 20;
          }
        };

        switch (section.type) {
          case 'title':
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text(section.text, 20, yOffset);
            yOffset += 10;
            break;

          case 'subtitle':
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.text(section.text, 20, yOffset);
            yOffset += 8;
            break;

          case 'paragraph':
            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');
            const lines = doc.splitTextToSize(section.text, 170);
            lines.forEach(line => {
              checkNewPage();
              doc.text(line, 20, yOffset);
              yOffset += 6;
            });
            yOffset += 4;
            break;

          case 'list':
            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');
            section.items.forEach((item, index) => {
              checkNewPage();
              const itemLines = doc.splitTextToSize(`${index + 1}. ${item}`, 160);
              itemLines.forEach(line => {
                doc.text(line, 25, yOffset);
                yOffset += 6;
              });
              yOffset += 2;
            });
            break;
        }
      });

      doc.save(solicitudReestructuracion.name);
    } catch (error) {
      console.error('Error al generar PDF:', error);
      setError('Error al generar el PDF. Por favor, inténtelo de nuevo.');
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="bg-gradient-to-r from-purple-600 to-blue-600 px-6 py-4">
          <h1 className="text-2xl font-bold text-white">Gestión de Documentación</h1>
        </div>

        <div className="p-6">
          {isLoading ? (
            <div className="flex justify-center items-center p-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
            </div>
          ) : error ? (
            <div className="p-4 bg-red-50 border-l-4 border-red-500 rounded-r-lg">
              <p className="text-red-700">{error}</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid gap-8">
              <div className="space-y-6">
                <div className="bg-gray-50 rounded-lg p-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Solicitud de Reestructuración
                  </h2>
                  <textarea
                    placeholder="Escriba aquí el texto para la sección III de la solicitud de reestructuración..."
                    value={textoUsuario}
                    onChange={(e) => setTextoUsuario(e.target.value)}
                    className="w-full h-40 p-3 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent resize-none"
                  />
                  <button
                    onClick={generarPDF}
                    className="mt-4 w-full px-6 py-3 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg hover:from-purple-700 hover:to-blue-700 transition-all duration-200 font-medium shadow-md hover:shadow-lg"
                    disabled={!cliente}
                  >
                    Generar PDF
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerarDocumentacion;